
import './styles.css';
import LoginRequired from './Login'
import Home from './Home';
import StateOCR from './StateOCR/Main';
import { Route, Routes, BrowserRouter } from "react-router-dom";


function App() {

 return (
  <LoginRequired>
      <BrowserRouter>
        <Routes>
          <Route element={<Home />} path="/" exact />
          <Route element={<StateOCR />} path="/state-ocr" exact />
        </Routes>
      </BrowserRouter>
  </LoginRequired>
 )

}

export default App;
