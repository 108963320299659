import { useState } from 'react';

// Material
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';


export const AutocompleteCustom = (props) => {
    const onChange = (evt, value) => {
        if (value !== null)
            props.onChange(value);
    }
    return ( <Autocomplete {...props} onChange={onChange} size="small" sx={{m: 1}} /> )
}


export function CustomDropdown(props) {

    return (
        <>
            <Autocomplete
                className='Dropdown'
                options={props.options}
                renderInput={(params) => <TextField {...params} label={props.label} />}
                onChange={(evt,value) => {
                    props.onChange(value);
                }} />
        </>
    )
}


export const DelayedTextField = (props) => {

    const [value, setValue] = useState(props.value);
    const [timeoutId, setTimeoutId] = useState(null);

    const onChange = (evt) => {
        if (timeoutId !== null) {
            clearTimeout(timeoutId);
        }
        const new_value = evt.target.value.toUpperCase();
        console.log(new_value)
        setValue(new_value);
        setTimeoutId(setTimeout(()=>{
            setTimeoutId(null);
            props.onChange(new_value);
        }, props.delay === undefined ? 1000 : props.delay));
    }

    return (
        <TextField {...props} value={value} onChange={onChange} />
    )
}