
import { Link } from "react-router-dom";

function Home() {
    return (
        <>  
            <h1>Home</h1>
            <ul>
                <li><Link to="/state-ocr">State / OCR</Link></li>
            </ul>
        </>
    )
}

export default Home;