import axios from 'axios';
import { useState, useEffect } from 'react';

// export const BASE_URL="http://qa.platelogiq.com:8080/";
// export const BASE_URL="http://annotation.vipr.ai/be";
export const BASE_URL="https://annotation.platelogiq.com/be";
// export const BASE_URL="https://18.208.70.202:8080/";

export const axios_instance = axios.create({
    baseURL: BASE_URL
});

axios_instance.hasBearer = false;
axios_instance.accessToken = "";
axios_instance.setToken = (token) => {
    axios_instance.accessToken = token;
    axios_instance.defaults.headers.common['Authorization'] = `Bearer ${token}`
}


  
function LoginRequired(parent_content) {

    const [loggedIn, setLoggedIn] = useState(false);

    const [user, setUser] = useState("");
    const [password, setPassword] = useState("");

    useEffect( () => {
        axios_instance.interceptors.response.use( res => res, async err => {
            console.log("Error response, current token:", axios_instance.accessToken);
            if (err.response.data.status_code === 401) {
                if (axios_instance.accessToken.length > 0) {
                    console.log("Refreshing access token", err);
                    try {
                        const res = await axios.post(`${BASE_URL}/auth/refresh`, {access_token: axios_instance.accessToken});
                        console.log("Token refreshed");
                        console.log("New token:", res.data.access_token)
                        axios_instance.setToken(res.data.access_token);
                        let config = err.config;
                        config.headers['Authorization'] = axios_instance.defaults.headers.common['Authorization'];
                        return axios_instance.request(config);
                    } catch(err) {
                        console.log("Failed to refresh");
                        axios_instance.setToken("");
                        setLoggedIn(false);
                        return Promise.reject(err);
                    };
                } else {
                    setLoggedIn(false);
                    return Promise.reject(err);
                }
            } else return Promise.reject(err);
        });
    }, []);

    const login = () => {
        console.log("Login attempt");
        axios_instance.post("auth/login", {
            user: user,
            password: password
        })
        .then( res => {
            console.log("Logged!!")
            setLoggedIn(true); // Login success
            axios_instance.setToken(res.data.access_token);
        })
        .catch( err => {
            alert("Login error")
            console.log("Error when logging in", err);
            setLoggedIn(false); // Login failed, check user and passwd
        })
    }

    const test_login = () => {
        axios_instance.get("auth/test-login")
        .then(res => { alert("Logged in"); })
        .catch(err => { alert("Not logged in"); })
    }

    if (!loggedIn) {
        return (
            <div className="Login-header">
                <input value={user} placeholder="Username" onChange={e => setUser(e.target.value)} />
                <input value={password} type="password" placeholder="Password" onChange={e => setPassword(e.target.value)} />
                <button onClick={login}>Login</button>
            </div>
        );
    } else {
        return (
            <div className="Main">
                <div className="Login-header">
                    <button onClick={test_login}>Login test</button>
                </div>
                <div className="MainContent">
                    {parent_content.children}
                </div>
            </div>
        )
    }
  }
  
  export default LoginRequired;