import { axios_instance, BASE_URL } from '../Login.js';
import React, { createRef } from 'react';

// Material
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Checkbox } from '@mui/material';

import { AutocompleteCustom, DelayedTextField } from '../Aux'


export class Item extends React.Component {

    
    constructor(props) {
        super(props);
        this._imgURL = `${BASE_URL}/images/aligned/_id/${this.props._id}`
        this.state = {
            selected: false,
            dataset: props.datasets.dict[props.dataset],
            state: props.states.dict[props.state],
            tag: props.ocr,
            imgURL: this._imgURL
        }
        this.isInitialMount = createRef(true);
        this.stackRef = createRef(null);
        this.rollBack = createRef(false);
    }

    updateImg() {
        this.setState({imgURL: `${this._imgURL}?timestamp=${Date.now()}`})
    }

    componentDidUpdate( prev_props, prev_state) {
        if (
            prev_state.dataset !== this.state.dataset ||
            prev_state.state !== this.state.state ||
            prev_state.tag !== this.state.tag
        ) {
            if (this.isInitialMount.current) {
                this.isInitialMount.current = false;
            } else {

                if (this.rollBack.current) { // Rolling back from error, don't need to update
                    this.rollBack.current = false;
                    return;
                }

                const data = {
                    id: this.props._id,
                    dataset: this.state.dataset ? this.state.dataset.id : null,
                    state: this.state.state ? this.state.state.code : null,
                    ocr: this.state.tag
                }
                axios_instance.post("states-ocr/update", data)
                .then(res => {
                    console.log(res)
                    if (this.stackRef.current !== null) {
                        this.stackRef.current.classList.add("Box-Anim-Success");
                        setTimeout(()=>{
                            this.stackRef.current.classList.remove("Box-Anim-Success");
                        }, 1000);
                    }
                }).catch( err => {
                    if (err.response.status === 403) {
                        alert("Current changes are not allowed");
                        this.rollBack.current = true;
                        this.setState(prev_state);
                    } else {
                        console.log(err);
                        alert("An error occured, check the console for more information.");
                    }
                })
            }
        }
    }

    render() {
        if (this.state.state === undefined) {
            return (<div>waiting</div>)
        }
    
        return (
            <fieldset ref={this.stackRef} className={"Box-Normal" + (this.state.selected ? " Box-Normal-Selected" : "")}>
                <legend style={{textAlign: 'left'}}>
                    <FormControlLabel  control={<Checkbox checked={this.state.selected} onClick={()=>this.setState({selected: !this.state.selected})}  />} label="Select" />
                </legend>
                <AutocompleteCustom
                    value={this.state.dataset}
                    options={this.props.datasets.array}
                    onChange={value => {this.setState({dataset: value})}}
                    renderInput={(params) => <TextField {...params}/>}
                />
                <AutocompleteCustom
                    value={this.state.state}
                    options={this.props.states.array}
                    onChange={value => {this.setState({state: value})}}
                    renderInput={(params) => <TextField {...params}/>}
                />
                <img
                    alt={`${this.state.tag} (${this.state.state.code})`}
                    src={this.state.imgURL}
                    onClick={()=>{this.props.startAlignmentEditor(this)}}
                    />
                <DelayedTextField
                    size="small"
                    inputProps={{style: {
                        textAlign: "center"
                    }}}
                    value={this.state.tag}
                    onChange={(value)=>{console.log(value); this.setState({tag: value})}}
                />
            </fieldset>
        )
    }
}