// import '../3dparty/jsfeat-min'
// const jsfeat = require("../3dparty/jsfeat-min.js")
import React, { useEffect } from 'react';
import { axios_instance, BASE_URL } from '../Login';

export function AlignmentEditor(props) {

	const keydown = (evt) => {
		if (evt.key === "Escape") props.close();
	}

	useEffect( ()=> {
		const ref = document.getElementById("editor")
		document.addEventListener("keydown", keydown);
		const editor = new Editor(ref);
		editor.saveData = (corners) => {
			console.log(corners)
			return axios_instance.put(`aligment/corners/${props._id}`, corners)
		};
		editor.loadImage(`${BASE_URL}/images/original/_id/${props._id}`, () => {
			axios_instance.get(`aligment/corners/${props._id}`)
			.then( (res) => { editor.load_corners(res.data.corners); } )
			.catch( () => alert("Error"))
		});

		return () => {
			document.removeEventListener("keydown", keydown);
		}
	})

	return (
		<div className='EditorMain'>
			<button onClick={props.close}>close</button>
			<div className='EditorContent' id="editor"/>
		</div>
	)

}



class Editor {

	constructor(container) {
		this.container = container;

		// Create canvas elements
		this.canvDraw 		= document.createElement("canvas");
		this.canvDraw.id 	= "canvas-draw";
		this.canvDraw.style = "position: absolute; z-index: 2;";
		this.ctxDraw 		= this.canvDraw.getContext("2d");
		container.appendChild(this.canvDraw);

		this.minW = 600;
		this.minH = 200;
		this.miniature 		= document.createElement("canvas");
		this.miniature.id 	= "canvas-mini";
		this.miniature.style= `
			position: fixed;
			z-index: 3;
			bottom: 0px;
			left: 0px;
			width: ${this.minW}px;
			height: ${this.minH}px;
			`;
		this.miniature.width= this.minW;
		this.miniature.height=this.minH;
		this.ctxMini 		= this.miniature.getContext("2d");
		container.appendChild(this.miniature);

		this.img_u8 = null;
		// this.new_img = new jsfeat.matrix_t(this.minW, this.minH, jsfeat.U8_t | jsfeat.C1_t);

		this.corners = null;
		this._img_loaded = false;
		this._img_original_width = null;
		this._img_original_height = null;
		this._is_drawing = false;
		this._selected_corner = "tl";
		this._reset_delta();

		// Mouse events
		this.canvDraw.addEventListener('mousedown'  , (event) => { this.mouseDown(event)   });
		this.canvDraw.addEventListener('mouseup'    , (event) => { this.mouseUp(event)     });
		this.canvDraw.addEventListener('mousemove'  , (event) => { this.mouseMove(event)   });
		this.canvDraw.addEventListener('contextmenu', (event) => { event.preventDefault()  });
		this.canvDraw.addEventListener('click'		, (event) => { event.preventDefault()  });

		this.saveData = null;
	}

	_reset_delta() {
		this._ref_pos = null;
		this._delta = {x: 0., y: 0.};
	}


	load_corners(corners) {
		Object.entries(corners).forEach( corner => {
			corner[1].x /= this._img_original_width;
			corner[1].y /= this._img_original_height;
		})
		this.corners = corners;
		this.draw();
	}
	

	_euclideanDist(pt1,pt2) {
		return Math.sqrt(
			Math.pow(pt1.x*this.canvDraw.width -pt2.x*this.canvDraw.width ,2) +
			Math.pow(pt1.y*this.canvDraw.height-pt2.y*this.canvDraw.height,2) );
	}


	selectClosestCorner(pos) {
		let selected_corner;
		let min_distance = Infinity;
		for (let corner of Object.entries(this.corners)) {
			const dist = this._euclideanDist(pos,corner[1])
			if (dist < min_distance) {
				min_distance = dist;
				selected_corner = corner[0]
			}
		}
		return selected_corner;
	}


	getJSONData() {
		return {
			tl: {x: this.corners.tl.x*this._img_original_width, y: this.corners.tl.y*this._img_original_height},
			tr: {x: this.corners.tr.x*this._img_original_width, y: this.corners.tr.y*this._img_original_height},
			br: {x: this.corners.br.x*this._img_original_width, y: this.corners.br.y*this._img_original_height},
			bl: {x: this.corners.bl.x*this._img_original_width, y: this.corners.bl.y*this._img_original_height}
		}
	}


	getRelativeMousePosition(event) {
		var rect = this.canvDraw.getBoundingClientRect();
		return {
			x: (event.clientX - rect.left)/this.canvDraw.width,
			y: (event.clientY - rect.top)/this.canvDraw.height
		}	
	}

	setDelta(pos) {
		this._delta = {
			x: (this._ref_pos.x-pos.x),
			y: (this._ref_pos.y-pos.y),
		}
	}


	mouseDown(event) {
		event.preventDefault();
		if (!this._is_drawing) {
			this._reset_delta();
			this._ref_pos = this.getRelativeMousePosition(event);
			this._selected_corner = this.selectClosestCorner(this._ref_pos)
			this._is_drawing = true;
			this.draw();
		}

	}


	mouseUp(event) {
		event.preventDefault();
		if (this._is_drawing) {
			this.setDelta(this.getRelativeMousePosition(event))
			this.corners[this._selected_corner].x -= this._delta.x
			this.corners[this._selected_corner].y -= this._delta.y
			this._is_drawing = false;
			this._reset_delta();

			this.saveData(this.getJSONData())
			.then( res => console.log(res))
			.catch( err => {console.log(err); alert("Error");})
		} 
		this.draw();
	}


	mouseMove(event) {
		event.preventDefault();
		if (this._is_drawing)
			this.setDelta(this.getRelativeMousePosition(event))
		this.draw();
	}


	loadImage(imgURL, onLoad=null) {
		this._img_loaded = false;
		this.img = new Image();
		this.img.crossOrigin = "Anonymous";
		this.img.src = imgURL;
		this.img.onload = () => {

			this._img_original_width  = this.img.width
			this._img_original_height = this.img.height
			
			// Resize image to fit container
			const ch = this.container.offsetHeight;
			const cw = this.container.offsetWidth;
			const propw = cw/this.img.width;
			const proph = ch/this.img.height;
			const factor = Math.min(propw,proph)
			this.img.width = this.img.width*factor
			this.img.height = this.img.height*factor

			// Resize canvas araes to match image
			this.canvDraw.width = this.img.width
			this.canvDraw.height = this.img.height

			// Apply offset to center
			const offsetX = 0.5*(cw-this.img.width);
			const offsetY = 0.5*(ch-this.img.height);
			this.canvDraw.style.left = offsetX + "px";
			this.canvDraw.style.top = offsetY + "px";

			// this.img_u8 = new jsfeat.matrix_t(this.img.width, this.img.height, jsfeat.U8_t | jsfeat.C1_t);

			this._img_loaded = true

			this.draw()

			if (onLoad) onLoad()
		}
	}


	draw() {

		let ctx = this.ctxDraw;
		let w = this.canvDraw.width; let h = this.canvDraw.height;

		if (this._img_loaded)
			ctx.drawImage(this.img,0,0,w,h);
		// let image_data = ctx.getImageData(0, 0, w, h);


		if (this.corners != null) {

			let tmpCorners = {
				tl: {x: this.corners.tl.x, y: this.corners.tl.y},
				tr: {x: this.corners.tr.x, y: this.corners.tr.y},
				br: {x: this.corners.br.x, y: this.corners.br.y},
				bl: {x: this.corners.bl.x, y: this.corners.bl.y},
			}

			if (this._is_drawing) {
				tmpCorners[this._selected_corner].x -= this._delta.x
				tmpCorners[this._selected_corner].y -= this._delta.y
			}

			const pt1 = {x: tmpCorners.tl.x*w, y: tmpCorners.tl.y*h};
			const pt2 = {x: tmpCorners.tr.x*w, y: tmpCorners.tr.y*h};
			const pt3 = {x: tmpCorners.br.x*w, y: tmpCorners.br.y*h};
			const pt4 = {x: tmpCorners.bl.x*w, y: tmpCorners.bl.y*h};

			ctx.beginPath();
			ctx.lineWidth = 8;
			ctx.strokeStyle = "rgba(255,0,255,1)"
			ctx.moveTo(pt1.x, pt1.y);
			ctx.lineTo(pt2.x, pt2.y);
			ctx.lineTo(pt3.x, pt3.y);
			ctx.lineTo(pt4.x, pt4.y);
			ctx.lineTo(pt1.x, pt1.y);
			ctx.fillStyle = "rgba(255,0,255,.3)"
			ctx.fill();
			ctx.stroke();
			ctx.closePath();


			// MINIATURE

			// const [mw,mh] = [this.minW,this.minH];

			// // Compute tranform	
			// let transform = new jsfeat.matrix_t(3, 3, jsfeat.F32_t | jsfeat.C1_t);
			// jsfeat.math.perspective_4point_transform(transform,
			// 	pt1.x, pt1.y, 0,  0,
			// 	pt2.x, pt2.y, mw,  0,
			// 	pt3.x, pt3.y, mw,  mh,
			// 	pt4.x, pt4.y, 0,  mh);
			// jsfeat.matmath.invert_3x3(transform, transform);
                
			// // Convert to grayscale
			// jsfeat.imgproc.grayscale(image_data.data, w, h, this.img_u8);

			// // Warp
			// jsfeat.imgproc.warp_perspective(this.img_u8, this.new_img, transform,0);

			// // Draw warped img to miniature
			// let image_data_mini = this.ctxMini.getImageData(0, 0, mw, mh);
			// let data_u32 = new Uint32Array(image_data_mini.data.buffer);
			// let alpha = (0xff << 24);
			// let i = this.new_img.cols*this.new_img.rows, pix = 0;
			// while(--i >= 0) {
			// 	pix = this.new_img.data[i];
			// 	data_u32[i] = alpha | (pix << 16) | (pix << 8) | pix;
			// }

			// this.ctxMini.putImageData(image_data_mini, 0, 0);

		}
	}


}
