import './StateOCR.css'
import { axios_instance } from '../Login';
import React, { useState, useEffect, useRef } from 'react';
import { DelayedTextField, CustomDropdown } from '../Aux';

// Material
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import { FormGroup } from '@mui/material';

import { AlignmentEditor } from './AlignmentEditor'
import { Item } from './Item'


function StateOCR() {

    const empty_results_data = {
        results: [],
        results_count: 0,
        total: 0
    }

    const empty_search_params = {
        page: 1,
        page_size: 10,
        dataset: null,
        state: null,
        tag: null,
        reverse: true
    }

    const [datasets, setDatasets] = useState(null);
    const [states, setStates] = useState(null);

    const [selDataset, setSelDataset] = useState(null);
    const [selState, setSelState] = useState(null);

    const [results, setResults] = useState(empty_results_data);
    const [params, _setParams] = useState(empty_search_params);
    const [alignmentEditorRef, setAlignmentEditorRef] = useState(null);

    const items_ref = useRef([])

    useEffect( () => {
        // Load all states
        axios_instance.get("states-ocr/all-states")
        .then(res => {
            let _states = res.data;
            _states.sort((a,b)=> (a.code < b.code)*-1)
            _states.forEach(element => {
                element.value = element.code;
                switch (element.code.slice(0,2)) {
                    case "ca": element.label = `Canada - ${element.name}`; break;
                    case "us": element.label = `USA - ${element.name}`; break;
                    default:
                        console.log(element)
                }
            });
            let statesDict = {}
            _states.forEach(st => {
                statesDict[st.code] = st;
            });
            setStates({array: _states, dict: statesDict});
        })

        // Load all datasets
        axios_instance.get("states-ocr/all-datasets")
        .then(res => {
            let _datasets = res.data;
            _datasets.forEach(ds => {
                ds.label = ds.name;
                ds.value = ds.id;
            });
            _datasets.sort((a,b) => (a.name < b.name)*-1);
            let datasetsDict = {}
            _datasets.forEach(ds => {
                datasetsDict[ds.id] = ds;
            });
            setDatasets({array: _datasets, dict: datasetsDict});
        })
    }, []);


    const setParams = (_params) => {
        setResults({...results, results: []});
        axios_instance.post("states-ocr/search", _params)
        .then( res => {
            console.log(res);
            setResults(res.data);
        })
        .catch( e => {
            alert("Error during search, check console");
            console.log("Error", e);
        });    
        _setParams(_params);
    }

    useEffect( () => {
        setParams(params);
    }, [])
    
    
    if (!states || !datasets) {
        return (
            <div>Loading filters data...</div>
        )
    }

    items_ref.current = [];

    return (
        <>
            <h1>State / OCR</h1>
            <div className='StateOCR-Search'>
                <CustomDropdown label="Datasets" options={datasets.array} onChange={(value) => {setParams({...params, page: 1, dataset: value ? value.id : value});}} />
                <CustomDropdown label="States" options={states.array} onChange={(value) => {setParams({...params, page: 1, state: value ? value.code : value});}} />
                <DelayedTextField label="Tag" onChange={(value)=>{console.log(value); setParams({...params, page: 1, tag: value})}} />
                Reverse <Switch checked={params.reverse} label="Reverse" onChange={(evt)=>{setParams({...params, reverse: evt.target.checked})}}/>
                <FormControl sx={{minWidth: 100}}>
                    <FormGroup>
                        <InputLabel>Page size</InputLabel>
                        <Select label="Page size" value={params.page_size} width={100} onChange={(evt,child)=>{setParams({...params, page_size: child.props.value})}}>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                            <MenuItem value={200}>200</MenuItem>
                        </Select>
                    </FormGroup>
                </FormControl>
            </div>
            <Stack alignItems="center">
                <Pagination sx={{m: "auto"}}
                    count={ Math.ceil(results.total/params.page_size) }
                    page={params.page}
                    onChange={(evt, p)=> {setParams({...params, page: p})}} />
            </Stack>

            <div className='StateOCRContent'>

                <div className="StateOCR-Results">
                    {results.results.map(item => <Item
                        key={item._id}
                        ref={(ref)=>items_ref.current.push(ref)}
                        states={states}
                        datasets={datasets}
                        startAlignmentEditor={ref=>setAlignmentEditorRef(ref)}
                        {...item} />)}
                </div>
                
                <div>
                    <fieldset className='SelectedActionsBar'>
                        <legend>Selected actions</legend>
                        <Button onClick={()=>{
                            items_ref.current.forEach((item=>{
                                if (item && !item.state.selected) {
                                    item.setState({selected: true})
                                }
                            }));
                        }}>Select all</Button>
                        <Button onClick={()=>{
                            items_ref.current.forEach((item=>{
                                if (item && item.state.selected) {
                                    item.setState({selected: false})
                                }
                            }));
                        }}>Deselect all</Button>
                        <CustomDropdown
                            label="new dataset" options={datasets.array} onChange={(value) => {
                                setSelDataset(value);
                            }} />
                        <CustomDropdown
                            label="new state" options={states.array} onChange={(value) => {
                                setSelState(value);
                            }} />
                        <Button disabled={!selState && !selDataset} variant="contained" onClick={()=>{
                            console.log("***Aqui", items_ref.current)
                            items_ref.current.forEach((item=>{
                                if (item && item.state.selected) {
                                    if (selState && selDataset) item.setState({state: selState, dataset: selDataset});
                                    else if (selState) item.setState({state: selState});
                                    else if (selDataset) item.setState({dataset: selDataset});
                                }
                            }));
                        }}>Apply</Button>
                    </fieldset>
                </div>
            </div>
            {alignmentEditorRef && <AlignmentEditor
                _id={alignmentEditorRef.props._id}
                close={()=>{
                    alignmentEditorRef.updateImg();
                    setAlignmentEditorRef(null);
                }}
                />}
        </>
    )
}

export default StateOCR;